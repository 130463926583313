import { useState, useEffect } from 'react';
import axios from 'axios';


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export const NEWS_API_KEY = process.env.REACT_APP_NEWS_API_KEY
export const WEATHER_API_KEY = process.env.REACT_APP_WEATHER_API_KEY
export const STRIPE_API = process.env.REACT_APP_STRIPE_API
export const GO0GLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
export const STRIPE_HANDLER_URL = process.env.REACT_APP_PAYMENT_HANDLER_URL
export const CAPTCHA_KEY = process.env.REACT_APP_CAPTCHA

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const AppConstants = {
    routes: {
    SIGN_IN: '/signin',
       SIGN_UP: '/register',
    CAMPAIGN: '/campaign',
        PAYMENT: '/payment',
        RESET_PASSWORD: '/resetpassword',
        DASHBOARD: '/dashboard',
        LANDING_PAGE: '/',
  },
  color: {
    PRIMARY: '#4DC5D7'
  }
}

export const markerDummies = [
  {lat: 6.5176, lon: 3.3538},
  {lat: 7.3775, lon: 3.9470},
  {lat: 5.6037, lon: 0.1870},
  {lat: 9.8965, lon: 8.8583},
  {lat: 6.5025, lon: 3.4025}
]

export function sanitizeBase64(base_string){
  if (base_string.match("data:image/jpeg;base64,")){
    const new_string = base_string.replace("data:image/jpeg;base64,", '');
    return [new_string, 'image/jpeg'];
  }
  if (base_string.match("data:image/jpg;base64,")){
    const new_string = base_string.replace("data:image/jpg;base64,", '');
    return [new_string, 'image/jpg'];
  }
  if (base_string.match("data:image/png;base64,")){
    const new_string = base_string.replace("data:image/png;base64,", '');
    return [new_string, 'image/png'];
  }
  return [base_string, ''];
}

export function base64toBlob(base64Data, contentType) {
  contentType = contentType || '';
  var sliceSize = 1024;
  var byteCharacters = Buffer.from(base64Data, 'base64').toString('base64');
  var bytesLength = byteCharacters.length;
  var slicesCount = Math.ceil(bytesLength / sliceSize);
  var byteArrays = new Array(slicesCount);

  for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}

export function convertDataURItoFile(dataURI, fileName) {
  // convert base64 to raw binary data held in a string
  var byteString = atob(dataURI.split(',')[1]);
  let fileType = "";

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ia], { type: mimeString });

  if (mimeString === "image/jpeg" || mimeString === "image/jpg"){
    fileType = "jpg";
  }

  if (mimeString === "image/png") {
    fileType = "png";
  }

  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  blob.lastModifiedDate = new Date();
  blob.name = fileName;
  //Cast to a File() type
  return new File([blob], `${fileName}.${fileType}`, {
    type: mimeString
  });
}

export const ledDummies = ['e-12', 'y-10', 'd-22', 'e-22', 'y-20'];



export const getNewsHeadlines = async (countrycode) => {
      return await axios.get(`https://newsapi.org/v2/top-headlines?country=${countrycode}&apiKey=${NEWS_API_KEY}`)
}

export const getWeatherReport = async (coords) => {
  return await axios.get(`https://api.weatherapi.com/v1/forecast.json?key=${WEATHER_API_KEY}&q=${coords.lat},${coords.lon}&days=5`)
}