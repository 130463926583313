import { SIDEBAR_OPEN, MAPVIEW_OPEN, SET_CONTINENT, SET_COUNTRY, SET_COUNTRY_CODE, 
    SET_NEWS_ARTICLES, SET_WEATHER_REPORT, CAMPAIGN_DATA, SET_USER_ID, IS_ADMIN, 
    BACK_LOG } from '../types';


const setSideBar = (payload) => ({
    type: SIDEBAR_OPEN,
    payload
});

const setMapViewOpen = (payload) => ({
    type: MAPVIEW_OPEN,
    payload
});

const setCountry = (payload) => ({
    type: SET_COUNTRY,
    payload
});

const setContinent = (payload) => ({
    type: SET_CONTINENT,
    payload
});

const setCountryCode = (payload) => ({
    type: SET_COUNTRY_CODE,
    payload
});

const setArticles = (payload) => ({
    type: SET_NEWS_ARTICLES,
    payload
})

const setWeatherReport = (payload) => ({
    type: SET_WEATHER_REPORT,
    payload
})

const setCampaignData = (payload) => ({
    type: CAMPAIGN_DATA,
    payload
})

const setUserId = (payload) => ({
    type: SET_USER_ID,
    payload
});

const setAdminUser = (payload) => ({
    type: IS_ADMIN,
    payload
});

const setBackLog = (payload) => ({
    type: BACK_LOG,
    payload
})

const appActions = { setSideBar, setMapViewOpen, setCountry, 
    setContinent, setCountryCode, setArticles, setWeatherReport, 
    setCampaignData, setUserId, setAdminUser, setBackLog }; 
export default appActions;