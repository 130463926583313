export const SIDEBAR_OPEN = "SIDEBAR_OPEN";
export const MAPVIEW_OPEN = "MAPVIEW_OPEN";
export const SET_COUNTRY = "SET_COUNTRY";
export const SET_CONTINENT = "SET_CONTINENT";
export const SET_COUNTRY_CODE = "SET_COUNTRY_CODE";
export const SET_NEWS_ARTICLES = "SET_NEWS_ARTICLES";
export const SET_WEATHER_REPORT = "SET_WEATHER_REPORT";
export const CAMPAIGN_DATA = "CAMPAIGN_DATA";
export const SET_USER_ID = "USER_ID";
export const EMPTY_DATA = "EMPTY DATA";
export const IS_ADMIN = "IS ADMIN";
export const BACK_LOG = "BACK LOG";
  