import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { AppConstants } from '../utils';
import React, { Suspense, lazy } from 'react';
import ClipLoader from "react-spinners/RotateLoader";

const ResetPassword = lazy(() => import("../pages/Onboard/ResetPassword"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const Campaign = lazy(() => import("../pages/Campaign"));
const Payment = lazy(() => import("../pages/Payment"));
const SignUp = lazy(() => import("../pages/Onboard/Signup"));
const SignIn = lazy(() => import("../pages/Onboard/SignIn"));
const Landing = lazy(() => import("../pages/Onboard/Landing"));


export default function Routes() {
  return (
    <Router>
      <Suspense fallback={<div style={{position: 'absolute', width: '100%', height: '100vh', backgroundColor: ' #00000080'}}>
        <ClipLoader color={AppConstants.color.PRIMARY} loading={true} css="display: block;
  margin: 0 auto;
  position: absolute; top: 40%; left: 40%;" size={150} />
      </div>}>
      <Switch>
      <Route path={AppConstants.routes.RESET_PASSWORD} component={ResetPassword} />
      <Route path={AppConstants.routes.DASHBOARD} component={Dashboard} />
      <Route path={AppConstants.routes.CAMPAIGN} component={Campaign} />
      <Route path={AppConstants.routes.PAYMENT} component={Payment} />
      <Route path={AppConstants.routes.SIGN_UP} component={SignUp} />
      <Route path={AppConstants.routes.SIGN_IN} component={SignIn} />
      <Route path={AppConstants.routes.LANDING_PAGE} component={Landing} />
      </Switch>
      </Suspense>
    </Router>
  );
}
