import {SIDEBAR_OPEN, MAPVIEW_OPEN, SET_CONTINENT, SET_COUNTRY, SET_COUNTRY_CODE, 
    SET_NEWS_ARTICLES, SET_WEATHER_REPORT, CAMPAIGN_DATA, SET_USER_ID, EMPTY_DATA, 
    IS_ADMIN, BACK_LOG} from "../types";

const INITIAL_STATE = {
    sidebarOpen: false,
    mapViewOpen: false,
    continent: null,
    country: null,
    listOfCountry: [],
    campaignTitle: '',
    campaignDescription: '',
    state: '',
    city: '',
    countryCode: '',
    headlines: [],
    weatherReport: {},
    taxiCoordinates: null,
    startDate: null,
    endDate: null,
    imageFile: null,
    campaignStartDate: null,
    campaignEndDate: null,
    name: null,
    uid: null,
    userToken: null,
    campaignSpan: null,
    isAdmin: false,
    backlog: [],
    totalUsers: 0,
}

const stateReducer = 
 (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SIDEBAR_OPEN:
            return { ...state, ...action.payload };
        case MAPVIEW_OPEN:
            return { ...state, ...action.payload };
        case SET_COUNTRY:
            return { ...state, ...action.payload };
        case SET_CONTINENT:
            return { ...state, ...action.payload };
        case SET_COUNTRY_CODE:
            return {...state, ...action.payload };
        case SET_NEWS_ARTICLES:
            return {...state, ...action.payload};
        case SET_WEATHER_REPORT:
            return {...state, ...action.payload};
        case CAMPAIGN_DATA:
            return {...state, ...action.payload};
        case SET_USER_ID:
            return {...state, ...action.payload};
        case EMPTY_DATA:
            return {};
        case IS_ADMIN:
            return {...state, ...action.payload};
        case BACK_LOG:
            return {...state, ...action.payload};
        default:
            return state;
    }
}

export default stateReducer;