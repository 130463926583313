import React from 'react';
import {Provider} from 'react-redux';
import Routes from './routes';
import store from './store';
import {STRIPE_API} from './utils';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import appActions from './store/actions';


export const stripePromise = loadStripe(STRIPE_API);

function App() {
  React.useEffect(() => {
    const isAdmin = localStorage.getItem('isAdmin');
    if (isAdmin != null){
      store.dispatch(appActions.setAdminUser({isAdmin: JSON.parse(isAdmin)}));
    }
  }, [])
  return (
    <Provider store={store}>
      <Elements stripe={stripePromise}>
      <Routes />
      </Elements>
    </Provider>
  );
}

store.subscribe(() => {
  console.log('Store Changed, ', store.getState());
});

export default App;
