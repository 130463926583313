import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import firebase from "firebase";
//import auth from "firebase/auth";



firebase.initializeApp({
  apiKey: "AIzaSyBBziBDKXlr5uCtSp5qlec3dlnAAvzXx6c",
  authDomain: "katalyze-980fa.firebaseapp.com",
  databaseURL: "https://modooh-4258e-default-rtdb.europe-west1.firebasedatabase.app/",
  projectId: "modooh-4258e",
  storageBucket: "gs://modooh-4258e.appspot.com",
  appId: "1:882494231821:web:6624544401e102aeaecc44"
});

export const auth = firebase.auth();
export const db = firebase.database();
export const storage = firebase.storage();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
